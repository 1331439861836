declare var window: any;

const showFlashMessage = (type: string, message: string): void => {
  const flashMessageContainer = document.querySelector("#flash-message");
  if (flashMessageContainer) {
    const flashMessage = document.createElement("div");
    flashMessage.classList.add("flash-message", "to-hide");
    flashMessage.innerHTML = `
      <div class='alert flash-msg alert-${type}'>
        ${message}
      </div>
    `;
    flashMessageContainer.prepend(flashMessage);
    setTimeout(() => {
      flashMessage.classList.add("hide");
      setTimeout(() => {
        flashMessage.remove();
      }, 500);
    }, 4500);
    window.scrollTo(0, 0);
  }
};

export default function handleFlashMessages(): void {
  window.addEventListener("load", () => {
    const flashMessages = document.querySelectorAll(".flash-message.to-hide");
    flashMessages.forEach((flashMessage) => {
      setTimeout(() => {
        flashMessage.classList.add("hide");
        setTimeout(() => {
          flashMessage.remove();
        }, 500);
      }, 4500);
    });
  });

  window.show_flash_msg = showFlashMessage;
}
