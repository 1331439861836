import JobPostBaseView from "../job_post_base_view";

export default class BaseAssignmentView extends JobPostBaseView {
  public mount() {
    super.mount();
    console.log("Assignment view mounted");
  }

  public unmount() {
    super.unmount();
  }
}
