interface DropzoneArgs {
  previewUrl: string;
  fileName: string;
  downloadUrl: string;
  originalUrl: string;
  fileInputFor: string;
}

const dropzoneStateInit = ({
  previewUrl,
  fileName,
  downloadUrl,
  originalUrl,
  fileInputFor,
}: DropzoneArgs): any => ({
  previewModal: false,
  dragEnter: false,
  drop: false,
  fileName: "",
  downloadUrl: "",
  originalUrl: "",
  init() {
    this.$watch("fileName", (fileName: any) => {
      updateFileNameContainer(fileInputFor, fileName);
    });

    if (previewUrl) {
      this.drop = true;
      this.fileName = fileName;
      this.downloadUrl = downloadUrl;
      this.originalUrl = originalUrl;
      this.$refs.previewContainer.innerHTML = this.buildPreviewImg(previewUrl);
    } else {
      this.drop = false;
    }
  },
  buildPreviewImg(src: string): string {
    return `<img loading="lazy" src="${src}" class="object-contain w-full h-full">`;
  },
  buildDefaultPreview(): string {
    return `
    <div class="flex flex-col justify-center items-center text-blue-dark w-full">
      <i data-feather="file" class="text-6xl"></i>
      <div class="text-center">${this.fileName}</div>
    </div>
    `;
  },
  clearInput(): void {
    this.drop = false;
    this.fileName = "";
    this.resetUrls();
    $(this.$refs.fileInput).val("");
  },
  verifyDocument(event: any): void {
    const input = event.target;
    const file = input.files[0];
    const acceptAttr = $(input).attr("accept");
    const isAllowedFileType = allowedFileType(file, acceptAttr);
    const isAllowedFileSize = allowedFileSize(file);

    if (isAllowedFileType && isAllowedFileSize) {
      this.previewFile(file);
      this.resetUrls();
      return;
    } else {
      if (!isAllowedFileSize) {
        alert("File size must be smaller than 10MB!");
        this.clearInput();
        return;
      }

      if (!isAllowedFileType) {
        alert(`Please select file with type ${acceptAttr || "image or PDF"}`);
        this.clearInput();
        return;
      }
    }
  },
  previewFile(file: any): void {
    this.dragEnter = false;
    this.drop = true;
    this.fileName = file.name;
    const previewContainer = this.$refs.previewContainer;
    const buildPreviewImg = this.buildPreviewImg;
    if (file.type.indexOf("image/") === 0) {
      let reader: any = new FileReader();
      reader.onload = function () {
        previewContainer.innerHTML = buildPreviewImg(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      previewContainer.innerHTML = this.buildDefaultPreview();
    }
  },

  resetUrls(): void {
    this.downloadUrl = "";
    this.originalUrl = "";
  },
});

function allowedFileType(file: any, acceptAttr: any): boolean {
  if (acceptAttr) {
    return verifyFileExtensions(file, acceptAttr);
  } else {
    return verifyDefaultFileType(file);
  }
}

function allowedFileSize(file: any): boolean {
  return file.size <= 1024 * 1024 * 100;
}

function verifyFileExtensions(file: any, acceptAttr: any): boolean {
  const allowedExtensionsArray = acceptAttr
    .split(",")
    .map((attr: any) => attr.trim());
  const allowedExtensionsRegex = extensionsToRegEx(allowedExtensionsArray);

  return !!allowedExtensionsRegex.exec(file.name);
}

function verifyDefaultFileType(file: any): boolean {
  return (
    file.type.indexOf("image/") === 0 ||
    file.type.indexOf("application/pdf") === 0
  );
}

function updateFileNameContainer(fileInputFor: string, fileName: string) {
  $(`[data-filename-of='${fileInputFor}']`).text(fileName);
}

var cache: any = {};

function extensionsToRegEx(exts: any) {
  var key = String(exts);
  if (cache.hasOwnProperty(key)) {
    return cache[key];
  }

  if (typeof exts === "string") {
    exts = formatExt(exts);
  } else if (Array.isArray(exts)) {
    exts = "(?:" + exts.map(formatExt).join("|") + ")";
  } else {
    throw new TypeError("expected a string or array");
  }

  return (cache[key] = new RegExp("\\." + exts + "$"));
}

function formatExt(str: any) {
  if (str.charAt(0) === ".") str = str.slice(1);
  return str.split(".").join("\\.");
}

export default dropzoneStateInit;
