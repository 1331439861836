import BaseView from "../../base_view";
import { changeNumberInput } from "../../shared/shared_functions";
declare var window: any;

export default class BasePositionView extends BaseView {
  public mount() {
    super.mount();
    changeNumberInput();
    this.handleMiniumExperienceFilter();
    this.handleChangeOverDate();
    console.log("Position View Mounted");
  }

  public unmount() {
    super.unmount();
    // Specific logic here
  }

  public addWindowFunctions(): void {
    super.addWindowFunctions();
    window.increaseCrew = (e: HTMLElement): void => {
      const input = e.closest(".input-group")?.querySelector("input");
      if (input) {
        const new_value = Number(input.value) + 1;
        input.value = new_value.toString();
      }
    };
    window.decreaseCrew = (e: HTMLElement): void => {
      const input = e.closest(".input-group")?.querySelector("input");
      if (input) {
        const new_value = Math.max(Number(input.value) - 1, 0);
        input.value = new_value.toString();
      }
    };
  }

  private handleMiniumExperienceFilter(): void {
    const minExpInput = document.getElementById("min_exp");
    if (minExpInput) {
      minExpInput.addEventListener("change", () => {
        const form = minExpInput.closest("form");
        if (form) {
          form.submit();
        }
      });
    }
  }

  private handleChangeOverDate(): void {
    const now = Date.now();
    const changeOverDateInput = document.getElementById(
      "position_change_over_date",
    );
    if (changeOverDateInput) {
      changeOverDateInput.addEventListener(
        "change",
        function (this: HTMLInputElement) {
          if (Date.parse(this.value) <= now) {
            const span = document.createElement("span");
            span.id = "change-over-date-note";
            span.textContent = "You set date from the past";
            const parent = this.parentElement;
            if (parent) {
              parent.appendChild(span);
            }
          } else if (Date.parse(this.value) > now) {
            const note = document.getElementById("change-over-date-note");
            if (note) {
              note.remove();
            }
          }
        },
      );
    }
  }
}
