function resizeMainView(expanded: boolean): void {
  // resize sidebar
  const sidebar = document.getElementById("sidebar");
  if (sidebar) {
    sidebar.classList.toggle("sidebar-inactive");
    sidebar.setAttribute("aria-expanded", `${!expanded}`);
  }
  // resize inner content
  const innerContent = document.querySelectorAll(".inner-content");
  innerContent.forEach((content) => {
    content.classList.toggle("active");
  });
}

function scrollMenu(scrollDistance: number): void {
  const sidebarMenuContainer = document.querySelector(
    ".sidebar-menu-container",
  );
  if (sidebarMenuContainer) {
    sidebarMenuContainer.scrollTop = scrollDistance;
  }
}

function toggleMenuItems(): void {
  const sidebarMenuGroups = document.querySelectorAll(
    ".sidebar-menu-group:not(.expanded) span",
  );
  sidebarMenuGroups.forEach((group) => {
    group.classList.toggle("hidden");
  });
  const sidebarHeader = document.querySelector(".sidebar-header");
  if (sidebarHeader) {
    sidebarHeader.classList.toggle("hidden");
  }
  const sidebarMenuItems = document.querySelector(".sidebar-menu-items");
  if (sidebarMenuItems) {
    sidebarMenuItems.classList.toggle("hidden");
  }
}

function applyMenuState(): void {
  const sidebar = document.getElementById("sidebar");
  if (sidebar) {
    const expanded = sidebar.getAttribute("aria-expanded") === "true";
    if (sessionStorage.getItem("expanded") === "false") {
      resizeMainView(expanded);
      toggleMenuItems();
    }

    const scrollDistance = sessionStorage.getItem("menuScrollDistance");
    if (scrollDistance) {
      scrollMenu(parseInt(scrollDistance, 10));
    }

    window.addEventListener("load", () => {
      document.body.classList.remove("preload");
    });

    window.addEventListener("unload", () => {
      const sidebarMenuContainer = document.querySelector(
        ".sidebar-menu-container",
      );
      if (sidebarMenuContainer) {
        const scrollTop = sidebarMenuContainer.scrollTop;
        sessionStorage.setItem("menuScrollDistance", scrollTop.toString());
      }
    });
  }
}

function setMenuState(): void {
  const sidebar = document.getElementById("sidebar");
  if (sidebar) {
    const timeout = sidebar.classList.contains("sidebar-inactive") ? 100 : 0;
    const expanded = sidebar.getAttribute("aria-expanded") === "true";
    sessionStorage.setItem("expanded", `${!expanded}`);

    resizeMainView(expanded);

    setTimeout(() => {
      toggleMenuItems();
    }, timeout);
  }
}

export { applyMenuState, setMenuState };
