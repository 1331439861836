declare var window: any;

export const changeNumberInput = (): void => {
  window.increaseNumber = (e: Event): void => {
    const target = e.target as HTMLElement;
    const input = target.closest(".input-group")?.querySelector("input");
    if (input) {
      const newValue = Number(input.value) + 1;
      input.value = newValue.toString();
    }
  };

  window.decreaseNumber = (e: Event): void => {
    const target = e.target as HTMLElement;
    const input = target.closest(".input-group")?.querySelector("input");
    if (input) {
      const newValue = Math.max(Number(input.value) - 1, 0);
      input.value = newValue.toString();
    }
  };
};
