import Alpine from "alpinejs";
import { LiveSocket } from "phoenix_live_view";
import { Socket, LongPoll } from "phoenix";
import Hooks from "./martide/live_hook";

import "./components/global_jquery.js";
import "./components/plausible.js";
import "./martide/sidebar.js";

// Include phoenix_html to handle method=PUT/DELETE in forms and buttons.
import "phoenix_html";

// Sentry
import * as Sentry from "@sentry/browser";
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://29fa387066fd4e598558c6da23750b7a@o73733.ingest.sentry.io/158808",
    environment: "prod",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
    denyUrls: [
      // Cloudflare Zaraz
      /my\.martide\.com\/cdn-cgi\/zaraz\/s.js/i,
    ],
  });
}
import "core-js/stable/index";
import "flowbite";
import loadView from "./views";
import { applyMenuState } from "./views/menu_helpers/toggle_menu";

declare var window: any;
window.Alpine = Alpine;

// Event Listeners

window.addEventListener("popstate", handlePopState);

window.addEventListener("click", handleRedirectLinkClick);

window.addEventListener("phx:close-modal", handleModalClose);

window.addEventListener("phx:open-modal", handleModalOpen);

window.addEventListener("phx:update-alert", handleUpdateAlert);

window.addEventListener("phx:download", handleDownload);

window.addEventListener("DOMContentLoaded", handleDOMContentLoaded, false);

window.addEventListener("unloaded", handleDocumentUnload, false);

window.addEventListener("track-form-changes", handleFormChanges);

// Functions
function handlePopState(e: PopStateEvent) {
  if (window.stopPopState && window.stopPopState()) {
    e.preventDefault();
    e.stopImmediatePropagation();
    history.go(1);
  }
}

function handleRedirectLinkClick(e: any) {
  const closestPhxBinding = e.target.closest(
    "a[data-phx-link='redirect'][href]",
  );

  const href = closestPhxBinding?.getAttribute("href");

  if (closestPhxBinding && window.stopLiveNav && window.stopLiveNav(href)) {
    e.preventDefault();
    e.stopImmediatePropagation();
  }
}

function handleModalClose(e: CustomEvent) {
  const closeBtn = document.getElementById(`${e.detail.id}-close-btn`);
  window.liveSocket.execJS(closeBtn, closeBtn?.getAttribute("phx-click"));
}

function handleModalOpen(e: CustomEvent) {
  const modal = document.getElementById(e.detail.id);

  if (modal) {
    window.liveSocket.execJS(modal, modal.getAttribute("data-exec-open"));
  }
}

function handleUpdateAlert(e: CustomEvent) {
  const event = new CustomEvent("flash", {
    detail: { type: e.detail.type, message: e.detail.message },
  });
  window.dispatchEvent(event);
}

const handleUnsavedForms = (event: BeforeUnloadEvent) => {
  const dirtyFormsCount = document.querySelectorAll(
    "form:not(.ignore-changes).form-dirty",
  ).length;

  if (dirtyFormsCount > 0) {
    event.returnValue =
      "You have unsaved changes, are you sure you want to discard them?";
  }
};

function handleFormChanges() {
  const inputTags = ["INPUT", "TEXTAREA", "SELECT"];

  // Select all target forms
  const forms = document.querySelectorAll("form:not(.ignore-changes)");

  forms.forEach((form: any) => {
    // Filter target inputs for changes
    const inputs = Array.from(form.elements).filter((el: any) =>
      inputTags.includes(el.tagName),
    );

    inputs.forEach((input: any) => {
      input.addEventListener("change", () => {
        form.classList.add("form-dirty");
      });
    });

    form.addEventListener("submit", () => {
      form.classList.remove("form-dirty");
    });
  });

  // Remove any existing listener before adding to avoid duplication since this function can be called more than once
  window.removeEventListener("beforeunload", handleUnsavedForms);
  window.addEventListener("beforeunload", handleUnsavedForms);
}

function handleDOMContentLoaded() {
  handleFormChanges();

  const feather = require("feather-icons");
  feather.replace();
}

function handleDownload(e: CustomEvent) {
  const downloadLink = document.getElementById(e.detail.id);

  if (downloadLink) {
    downloadLink.click();
  }
}

const viewName = document.querySelector("body")?.dataset.jsViewName;
const ViewClass = loadView(viewName!);
const view = new ViewClass();
view.addWindowFunctions();
applyMenuState();
view.mount();
window.currentView = "view";

Alpine.start(); // Alpine initialization

function handleDocumentUnload() {
  window.currentView.unmount();
}

// LiveSocket Configuration

const csrfToken = document
  .querySelector("meta[name='csrf-token']")!
  .getAttribute("content");

let liveSocket: any = new LiveSocket("/live", Socket, {
  dom: {
    onBeforeElUpdated(from: any, to: any) {
      // Alpine.JS support
      if (from._x_dataStack) {
        (window as any).Alpine.clone(from, to);
      }

      // Ensure Select hooks are updated
      if (from.getAttribute("phx-hook") === "Select" && from.isEqualNode(to)) {
        from.classList.add("tomselected", "ts-hidden-accessible");
        from.blur();
      }

      return true;
    },
  },
  hooks: Hooks,
  params: { _csrf_token: csrfToken },
});

liveSocket.metadata = {
  click: (e: any, _el: any) => {
    return {
      altKey: e.altKey,
      shiftKey: e.shiftKey,
      ctrlKey: e.ctrlKey,
      metaKey: e.metaKey,
      x: e.x || e.clientX,
      y: e.y || e.clientY,
      pageX: e.pageX,
      pageY: e.pageY,
      screenX: e.screenX,
      screenY: e.screenY,
      offsetX: e.offsetX,
      offsetY: e.offsetY,
      detail: e.detail || 1,
    };
  },
  keydown: (e: any, _el: any) => {
    return {
      altGraphKey: e.altGraphKey,
      altKey: e.altKey,
      code: e.code,
      ctrlKey: e.ctrlKey,
      key: e.key,
      keyIdentifier: e.keyIdentifier,
      keyLocation: e.keyLocation,
      location: e.location,
      metaKey: e.metaKey,
      repeat: e.repeat,
      shiftKey: e.shiftKey,
    };
  },
};

liveSocket.socket.onError(
  (_error: any, transport: any, establishedConnections: any) => {
    if (transport === WebSocket && establishedConnections === 0) {
      liveSocket.socket.replaceTransport(LongPoll);
      liveSocket.connect();
    }
  },
);

liveSocket.connect();
window.liveSocket = liveSocket;
