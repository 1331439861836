import BaseView from "../../base_view";

// Declarations
declare let google: any;
declare var window: any;

export default class BasePublicCompanyView extends BaseView {
  public mount() {
    super.mount();
    this.clickTab();
    if (window.list_js_map !== undefined) {
      this.handleCompanyMap();
    }
    this.clickTab();
    console.log("Public Company View Mounted");
  }

  public unmount() {
    super.unmount();
  }

  private clickTab(): void {
    const fleetTab = document.getElementById("fleet-tab");
    if (fleetTab) {
      fleetTab.addEventListener("click", () => {
        window.location.assign(
          "?" + new URLSearchParams({ tab: "fleet" }).toString(),
        );
      });
    }

    const jobTab = document.getElementById("job-tab");
    if (jobTab) {
      jobTab.addEventListener("click", () => {
        window.location.assign(
          "?" + new URLSearchParams({ tab: "job" }).toString(),
        );
      });
    }

    const aboutTab = document.getElementById("about-tab");
    if (aboutTab) {
      aboutTab.addEventListener("click", () => {
        window.location.href = window.location.pathname;
      });
    }

    const awardTab = document.getElementById("award-tab");
    if (awardTab) {
      awardTab.addEventListener("click", () => {
        window.location.assign(
          "?" + new URLSearchParams({ tab: "award" }).toString(),
        );
      });
    }
  }

  private handleCompanyMap() {
    const googleMapApiKeyInput = document.getElementById("google_map_key");
    const googleMapApiKey = googleMapApiKeyInput?.getAttribute("value");
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapApiKey}`;
    script.onload = () => {
      window.list_js_map.map((i: any) => {
        // default to Hong Kong
        const latitude =
          i.coordinates.lat === null ? 22.286394 : i.coordinates.lat;
        const longitude =
          i.coordinates.lon === null ? 114.149139 : i.coordinates.lon;

        const ctr = { lat: latitude, lng: longitude };
        const addressMap = new google.maps.Map(
          document.getElementById("mapId-" + i.id),
          {
            zoom: 10,
            center: ctr,
          },
        );
        new google.maps.Marker({
          position: ctr,
          map: addressMap,
        });
      });
    };

    document.head.appendChild(script);
  }
}
