import PositionView from "./crewing/position";
import CrewChangeView from "./planning/crew_change";
import PublicCompanyView from "./public/company";
import AssignmentView from "./job_post/assignment";
import BaseView from "./base_view";

// Collection of specific view modules
const views: any = {
  AssignmentView,
  CrewChangeView,
  PositionView,
  PublicCompanyView,
};

export default function loadView(view: any) {
  const viewLookUp = view.split(".");
  const actionName = viewLookUp.length === 1 ? "" : viewLookUp.pop();
  const viewName = viewLookUp.join("");
  const actionLookup = views[viewName];
  if (actionLookup) {
    return actionLookup(actionName);
  } else {
    return BaseView;
  }
}
