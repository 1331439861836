import BaseView from "../../base_view";
import select2Selectors from "../../form_helpers/select2_selectors";
export default class BasePlanningCrewChangeView extends BaseView {
  public mount() {
    super.mount();
    console.log("CrewChange View Mounted");
  }

  public addWindowFunctions() {
    super.addWindowFunctions();
    const openFormModal = (res: any): void => {
      if (res.html) {
        $(".modal-content").html(res.html);
        select2Selectors();
      } else {
        console.log(res.errors);
      }
    };
    (window as any).load_crew_list = (event: any) => {
      $.get(`/app/operations/${event.value}/load_crew_list`)
        .done((html: any) => {
          $("#crew_list").html(html);
          select2Selectors();
        })
        .fail((err: any) => console.log(err));
    };
    (window as any).openForm = (e: any): void => {
      const url = $(e).data("url");

      $.get(url)
        .done((html: string) => {
          openFormModal(html);
        })
        .fail((err: any) => {
          if (err.responseJSON) {
            (window as any).show_flash_msg("warning", err.responseJSON.flash);
          } else {
            (window as any).show_flash_msg("warning", err.statusText);
          }
        });
    };
    (window as any).openModal = (form: any): void => {
      const formData = new FormData(form);
      const queryString = new URLSearchParams(formData as any).toString();

      $('button[type="submit"]')
        .html("<i data-feather='loader' class=''></i> Processing...")
        .attr("disabled", "true");

      $.ajax({
        url: form.action,
        type: form.method,
        data: queryString,
        processData: false,
        contentType: false,
      })
        .done((res: any) => {
          openFormModal(res);
        })
        .fail((err: any) => {
          if (err.responseJSON) {
            openFormModal(err.responseJSON);
          }
        });
    };
    (window as any).submitCrewChangeForm = (form: any): void => {
      const formData = new FormData(form);

      $('button[type="submit"]')
        .html("<i data-feather='loader' class=''></i> Processing...")
        .attr("disabled", "true");

      $.ajax({
        url: form.action,
        type: form.method,
        data: formData,
        processData: false,
        contentType: false,
      })
        .done((res: any) => {
          if (res.redirect_path) {
            (window as any).location.href = res.redirect_path;
          }
        })
        .fail((err: any) => {
          if (err.responseJSON) {
            openFormModal(err.responseJSON);
          }
        });
    };
    (window as any).updateCrewChangeStatus = function (
      status: string,
      url: string,
      csrfToken: string,
    ) {
      $.ajax(url, {
        data: {
          crew_change: { status: status },
        },
        type: "PUT",
        headers: {
          "x-csrf-token": csrfToken,
        },
      });
    };
  }
}
