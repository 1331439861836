declare let $: any;
import BaseView from "../base_view";
import "../../components/global_jquery.js";

export default class JobPostBaseView extends BaseView {
  public mount() {
    super.mount();
    console.log("JobPost Base View Mounted");
  }

  public addWindowFunctions() {
    super.addWindowFunctions();
  }

  public unmount() {
    super.unmount();
    console.log("MainView unmounted");
  }

  public fillDocumentValidationForm() {
    $(document).on("change", "[id^=document_type_]", function (this: any) {});
  }
}
