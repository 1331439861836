declare var window: any;
export default function togglePasswordShow() {
  window.togglePasswordShow = (event: Event): void => {
    const target = event.target as HTMLElement;
    const input = target.closest(".form-group")?.querySelector("input");
    if (input instanceof HTMLInputElement) {
      input.type = input.type === "text" ? "password" : "text";
    }
  };
}
