import select2Selectors from "./form_helpers/select2_selectors";
import { setMenuState } from "./menu_helpers/toggle_menu";
import flashMessageStateInit from "../components/FlashMessage";
import dropzoneStateInit from "../components/Dropzone";
import handleFlashMessages from "./helpers/flash_message";
import togglePasswordShow from "./form_helpers/toggle_password_show";
import Glide from "@glidejs/glide";
declare var window: any;

interface ViewInterface {
  mount(): void;
  unmount(): void;
  addWindowFunctions(): void;
}

export default class BaseView implements ViewInterface {
  public mount() {
    handleFlashMessages();
    // handleSearchForm()
    select2Selectors();
    document.onkeydown = (evt: KeyboardEvent): void => {
      const modalForm = document.querySelector(".modal");
      if (evt.code === "Escape" && modalForm) {
        // Hide modal using native JavaScript
        modalForm.classList.remove("show");
        modalForm.setAttribute("aria-modal", "false");
        modalForm.setAttribute("style", "display: none");
      }
    };
    const messageContainer = document.getElementById("messageContainer");
    if (messageContainer?.dataset.conversationId) {
      window.readNotification(messageContainer.dataset.conversationId);
    }
    this.handleGenerateDownload();
    this.handleSliders();
    this.handleLeftMenu();
    console.log("MainView Mounted");
  }

  public unmount() {
    console.log("MainView unmounted");
  }

  public addWindowFunctions() {
    togglePasswordShow();
    // Window function for flash message state in alpine js' x-data directive
    window.Alpine.data("flashMessage", flashMessageStateInit);
    window.Alpine.data("dropzone", dropzoneStateInit);
  }

  public handleSliders() {
    const settings: any = {
      type: "slider",
      startAt: 0,
      breakpoints: {
        2000: { perView: 3 },
        1024: { perView: 2 },
        640: { perView: 1 },
      },
    };

    const sliders = document.querySelectorAll(".glide");
    sliders.forEach((item: any) => {
      new Glide(item, settings).mount();
    });
  }

  public handleLeftMenu(): void {
    const collapseMenuItems = document.querySelectorAll(
      ".collapse-menu a, .collapse-menu button",
    );
    collapseMenuItems.forEach((item) => {
      item.addEventListener("click", () => {
        setMenuState();
      });
    });
  }

  private handleGenerateDownload(): void {
    window.download = (filename: string, text: string) => {
      const el = document.createElement("a");
      el.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(text),
      );
      el.setAttribute("download", filename);
      el.style.display = "none";
      document.body.appendChild(el);
      el.click();
      document.body.removeChild(el);
    };
  }
}
